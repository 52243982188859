/** @jsxImportSource @emotion/react */
import { Box, Button, Grid, Popover, useTheme } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Calendar, DateRange } from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";
import FlatSelect from "./FlatSelect";

const NowrDatePicker = (props: {
	customOnly?: boolean;
	oneDayOnly?: boolean;
	cancellable?: boolean;
	label?: string;
	initialRange?: { startDate: Date; endDate: Date };
	range?: { startDate: Date; endDate: Date };
	onChange?: (option: { value: string; range?: { startDate: Date; endDate: Date } }) => void;
	variant?: string;
}) => {
	const theme = useTheme();
	const [ranges, setRanges] = useState([
		{
			startDate: props.initialRange?.startDate || props.range?.startDate || new Date(),
			endDate: props.initialRange?.endDate || props.range?.endDate || new Date(),
			key: "selection",
		},
	]);
	const [dateVisible, setDateVisible] = useState(false);
	const [selectedChoice, setSelectedChoice] = useState(() => {
		if (props.initialRange || props.range) {
			return "custom";
		}
		if (props.customOnly) {
			return "choose";
		}
		return "any";
	});
	const prevChoice = useRef(selectedChoice);
	const prevRanges = useRef(ranges);
	const anchorRef = useRef<any>();

	useEffect(() => {
		// console.log('3w232', selectedChoice, props.range, props.customOnly)
		setSelectedChoice(props.range ? "custom" : props.customOnly ? "choose" : "any");
		setRanges([
			{
				startDate: props.range?.startDate || new Date(),
				endDate: props.range?.endDate || new Date(),
				key: "selection",
			},
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.range]);

	const getRangeString = () => {
		const range = ranges?.[0];
		if (!range?.startDate) return "";
		const start = moment(range.startDate);
		const end = moment(range.endDate || range.startDate);
		let str = start.locale("it-IT").format("D MMM YYYY");
		if (!end.isSame(start, "day")) {
			str += " → " + end.locale("it-IT").format("D MMM YYYY");
		}
		return str;
	};

	const choices = [
		{ name: "Qualsiasi data", value: "any" },
		{ name: "Now", value: "now" },
		{ name: "Oggi", value: "today" },
		{ name: getRangeString(), value: "custom", hidden: selectedChoice !== "custom" },
		{ name: "Scegli una data", value: "choose" },
	];

	const onRangeCancel = () => {
		setSelectedChoice(prevChoice.current);
		if (prevRanges.current) {
			setRanges(prevRanges.current);
		}
	};

	const onDialogCancel = () => {
		onRangeCancel();
		setDateVisible(false);
	};

	const onDialogConfirm = () => {
		setSelectedChoice("custom");
		props.onChange?.({ value: "custom", range: ranges && ranges[0] });
		setDateVisible(false);
	};

	const DatePickerComp: any = props.oneDayOnly ? Calendar : DateRange;

	return (
		<React.Fragment>
			<div
				ref={(ref) => (anchorRef.current = ref)}
				style={{ cursor: "pointer" }}
				onClick={
					props.customOnly
						? () => {
								prevChoice.current = selectedChoice;
								setDateVisible(true);
						  }
						: undefined
				}
			>
				<FlatSelect
					disabled={props.customOnly}
					value={selectedChoice}
					choices={choices}
					label={props.label}
					onChange={(choice) => {
						prevChoice.current = selectedChoice;
						setSelectedChoice(choice);
						if (choice === "choose") {
							setDateVisible(true);
						} else {
							props.onChange?.({ value: choice });
						}
					}}
				/>
			</div>

			<Popover
				anchorEl={anchorRef.current}
				open={dateVisible}
				// onClose={onDialogCancel}
				onClose={onDialogConfirm}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<DatePickerComp
					css={{
						".rdrDays": {
							"button span": {
								fontSize: 14,
								borderRadius: 0,
							},
							"button.rdrDayToday span.rdrDayNumber > span::after": {
								background: theme.palette.primary.main,
							},
							"button.rdrDay span.rdrDayNumber::after": {
								borderRadius: 0,
							},
						},
						".rdrMonthAndYearWrapper > .rdrMonthAndYearPickers": {
							fontSize: 16,
						},
						".rdrMonthAndYearWrapper > button": {
							borderRadius: 0,
							// background: '#eee',
							background: "white",
							// border: '1px solid black',
							border: "1px solid rgb(150, 150, 150)",
						},
					}}
					//!oneDayOnly
					variant={props?.variant}
					moveRangeOnFirstSelection
					date={ranges?.[0]?.startDate}
					ranges={ranges}
					locale={rdrLocales.it}
					onChange={(value) => {
						setSelectedChoice("custom");
						prevRanges.current = ranges;

						if (props.oneDayOnly) {
							setRanges([{ key: "date", startDate: value, endDate: value }]);
						} else {
							setRanges([value.selection]);
						}

						// props.onChange?.({ value: 'custom', range: value.selection });
					}}
					color={theme.palette.secondary.main}
					rangeColors={[theme.palette.secondary.main]}
					showDateDisplay={false}
					showMonthAndYearPickers={false}
					showSelectionPreview={false}
				/>

				<Grid container justifyContent="flex-end" style={{ marginBottom: 10 }}>
					{props.customOnly && props.cancellable && (
						<Box flex={1}>
							<Button
								children="cancella"
								style={{ marginLeft: 10 }}
								onClick={() => {
									setSelectedChoice(props.customOnly ? "choose" : "any");
									setDateVisible(false);
								}}
							/>
						</Box>
					)}

					<Button children="annulla" style={{ color: theme.palette.text.secondary }} onClick={onDialogCancel} />
					<Button children="ok" onClick={onDialogConfirm} />
				</Grid>
			</Popover>
		</React.Fragment>
	);
};

export default NowrDatePicker;
